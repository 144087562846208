<script setup>
import { SpeedInsights } from '@vercel/speed-insights/nuxt';
import AppBar from '~/components/layout/AppBar.vue';

const route = useRoute();
const utilsStore = useUtilsStore();
onMounted(() => {
  utilsStore.setGlobalLoader(false);
  utilsStore.setEmbedStatus(true);
});
onUnmounted(() => {
  utilsStore.setEmbedStatus(false);
});
</script>

<template>
  <v-layout class="embed-layout">
    <SpeedInsights :sample-rate="0.5" />

    <v-main>
      <AppBar v-if="route.meta.showEmbedAppBar" />
      <slot />
    </v-main>
  </v-layout>
</template>
